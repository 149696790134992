@include form-control-placeholder($default-color, 1);

.form-control {
  background-color: $transparent-bg;
  border: 2px solid $primary-color;
  border-radius: $btn-round-radius;
  line-height: normal;
  font-weight: 300;
  font-size: $font-size-base;
  height: $height-elm-form!important;
  color: $primary-color;
  @include transition-input-focus-color();
  @include box-shadow(none);

  &.with-shadow{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #00000029;
    border-radius: 16px;
    border: none;
  }

  &:focus {
    border-color: $primary-color;
    @include box-shadow(none);
    outline: 0 !important;
    color: $black-color;

    & + .input-group-append .input-group-text,
    & ~ .input-group-append .input-group-text,
    & + .input-group-prepend .input-group-text,
    & ~ .input-group-prepend .input-group-text {
      border: 1px solid $primary-color;
      border-left: none;
      background-color: $transparent-bg;
    }
  }

  .has-success &,
  .has-error &,
  .has-success &:focus,
  .has-error &:focus {
    @include box-shadow(none);
  }

  .has-danger &,
  .has-success & {
    &.form-control-success,
    &.form-control-danger {
      background-image: none;
    }
  }

  & + .form-control-feedback {
    border-radius: $border-radius-large;
    font-size: $font-size-base;
    margin-top: -7px;
    position: absolute;
    right: 10px;
    top: 50%;
    vertical-align: middle;
  }

  .open & {
    border-radius: $border-radius-large $border-radius-large 0 0;
    border-bottom-color: transparent;
  }

  & + .input-group-append .input-group-text,
  & + .input-group-prepend .input-group-text {
    background-color: $white-bg;
  }

  &::-webkit-input-placeholder { /* Edge */
    font-size: $font-size-base;
    color: $primary-color;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: $font-size-base;
    color: $primary-color;
  }
  &::placeholder {
    font-size: $font-size-base;
    color: $primary-color;
  }
}

.has-success .input-group-append .input-group-text,
.has-success .input-group-prepend .input-group-text,
.has-success .form-control {
  border-color: $light-gray;
}

.has-success .form-control:focus,
.has-success.input-group-focus .input-group-append .input-group-text,
.has-success.input-group-focus .input-group-prepend .input-group-text {
  border-color: lighten($success-color, 5%);
}

.has-danger .form-control,
.has-danger .input-group-append .input-group-text,
.has-danger .input-group-prepend .input-group-text,
.has-danger.input-group-focus .input-group-prepend .input-group-text,
.has-danger.input-group-focus .input-group-append .input-group-text {
  border-color: lighten($danger-color, 30%);
  color: $danger-color;
  background-color: rgba(222, 222, 222, 0.1);

  &:focus {
    background-color: $white-color;
  }
}

.has-success,
.has-danger {
  &:after {
    font-family: "Nucleo Outline";
    content: "\ea22";
    display: inline-block;
    position: absolute;
    right: 20px;
    bottom: 10px;
    color: $success-color;
    font-size: 11px;
  }

  &.form-control-lg {
    &:after {
      font-size: 13px;
      top: 24px;
    }
  }

  &.has-label {
    &:after {
      top: 35px;
    }
  }

  .form-control + label,
  &.form-check .form-check-label label,
  &.form-check:after {
    display: none !important;
  }

  &.form-check .form-check-label {
    color: $success-color;
  }
}

.has-danger {
  &:after {
    content: "\ea53";
    color: $danger-color;
  }

  &.form-check .form-check-label {
    color: $danger-color;
  }
}

@include form-control-lg-padding(
  $padding-large-vertical,
  $padding-input-horizontal
);
@include input-base-padding($padding-input-vertical, $padding-input-horizontal);

.form-group.no-border,
.input-group.no-border {
  .form-control,
  .form-control + .input-group-prepend .input-group-text,
  .form-control + .input-group-append .input-group-text {
    background-color: $opacity-gray-3;
    border: medium none;
    &:focus,
    &:active,
    &:active {
      border: medium none;
      background-color: $opacity-gray-5;
    }
  }

  .form-control {
    &:focus {
      & + .input-group-prepend .input-group-text,
      & + .input-group-append .input-group-text {
        background-color: $opacity-gray-5;
      }
    }
  }

  .input-group-prepend .input-group-text,
  .input-group-append .input-group-text {
    background-color: $opacity-gray-3;
    border: none;
  }
}

.has-error {
  .form-control-feedback,
  .control-label {
    color: $danger-color;
  }
}
.has-success {
  .form-control-feedback,
  .control-label {
    color: $success-color;
  }
}

.input-group-append .input-group-text,
.input-group-prepend .input-group-text {
  background-color: transparent;
  border: 1px solid $light-gray;
  border-radius: $btn-round-radius;
  color: $default-color;

  & i {
    opacity: 0.5;
  }

  @include transition-input-focus-color();

  .has-danger.input-group-focus & {
    background-color: $white-color;
  }

  .has-success & {
    background-color: $white-color;
  }
  .has-danger .form-control:focus + & {
    color: $danger-color;
  }
  .has-success .form-control:focus + & {
    color: $success-color;
  }

  & + .form-control,
  & ~ .form-control {
    @include input-size($padding-base-vertical - 1, $padding-base-horizontal);
    padding-left: 18px;
  }

  i {
    width: 17px;
  }
}

.input-group-append,
.input-group-prepend {
  margin: 0;
}

.input-group-append .input-group-text {
  border-left: none;
}
.input-group-prepend .input-group-text {
  border-right: none;
}

.input-group-focus {
  .input-group-prepend .input-group-text,
  .input-group-append .input-group-text {
    background-color: $white-bg;
    border-color: $primary-color;
  }

  &.no-border {
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      background-color: $opacity-gray-5;
    }
  }
}

.input-group,
.form-group {
  margin-bottom: 10px;
  position: relative;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  -moz-appearance: textfield !important;

  .form-control-static {
    margin-top: 9px;
  }
}
.input-group[disabled] {
  .input-group-prepend .input-group-text,
  .input-group-append .input-group-text {
    background-color: $light-gray;
  }
}

.input-group .form-control:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child) {
  border-radius: $btn-round-radius;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0 none;
}

.input-group .form-control:first-child,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-right: 0 none;
}
.input-group .form-control:last-child,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
  border-left: 0 none;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: $light-gray;
  color: $default-color;
  cursor: not-allowed;
}

.input-group-btn .btn {
  border-width: $border-thin;
  padding: $padding-btn-vertical $padding-base-horizontal;
}
.input-group-btn .btn-default:not(.btn-fill) {
  border-color: $medium-gray;
}

.input-group-btn:last-child > .btn {
  margin-left: 0;
}
textarea.form-control {
  max-width: 100%;
  max-height: 80px;
  padding: 10px 10px 0 0;
  resize: none;
  border: none;
  border-bottom: 1px solid $light-gray;
  border-radius: 0;
  line-height: 2;

  &:focus,
  &:active {
    border-left: none;
    border-top: none;
    border-right: none;
  }
}

.has-success,
.has-danger {
  &.form-group .form-control,
  &.form-group.no-border .form-control {
    padding-right: $padding-input-horizontal + 21;
  }
}

.form.form-newsletter .form-group {
  float: left;
  width: 78%;
  margin-right: 2%;
  margin-top: 9px;
}

.input-group .input-group-btn {
  padding: 0 12px;
}

// Input files - hide actual input - requires specific markup in the sample.
.form-group input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.form-group .form-group__svg{
  position: absolute;
  top: 12px;
  right: 15px;
}

.form-group .form-group__icon {
  top: unset;
  bottom: 10px;
  cursor: pointer;
}

.form-text {
  font-size: $font-size-small;
}

.form-control-lg {
  padding: 0;
  font-size: inherit;
  line-height: 0;
  border-radius: 0;
}

.form-horizontal {
  .col-form-label,
  .label-on-right {
    padding: 10px 5px 0 15px;
    text-align: right;
    max-width: 180px;
  }

  .checkbox-radios {
    margin-bottom: 15px;

    .form-check:first-child {
      margin-top: 8px;
    }
  }

  .label-on-right {
    text-align: left;
    padding: 10px 15px 0 5px;
  }

  .form-check-inline {
    margin-top: 6px;
  }
}

.label-group{
  color: $dark !important;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px !important;
  padding-bottom: 10px;
}

.custom-input-outline{

  &:focus{
    color: $primary-color;
    border: 2px solid $primary-color;
  }

  &::placeholder { 
    color: $primary-color;
    opacity: 1; 
  }
}

.custom-textarea{
  border: 1px solid #eee;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
  width: 100%;
  &.with-shadow{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #00000029;
    border-radius: 16px;
    border: none;
  }
  &:focus { 
    outline: none !important;
    border-color: $primary-color;
  }
}
.label-textarea{
  color: $primary-color !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding-left: 10px;
}

