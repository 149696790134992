.btn,
.navbar .navbar-nav > a.btn {
  border-width: $border-thick;
  font-weight: $font-weight-medium;
  font-size: $font-size-base;
  line-height: $line-height;
  border: none;
  margin: 10px 1px;
  padding: $padding-btn-vertical $padding-btn-horizontal;
  cursor: pointer;
  text-align: left;

  @include btn-styles($default-color, $default-states-color);

  &:hover,
  &:focus {
    @include opacity(1);
    outline: 0 !important;
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    @include box-shadow(none);
    outline: 0 !important;
  }

  .badge {
    margin: 0;
  }

  &.btn-icon {
    // see above for color variations
    height: $btn-icon-size-regular;
    min-width: $btn-icon-size-regular;
    width: $btn-icon-size-regular;
    padding: 0;
    font-size: $btn-icon-font-size-regular;
    overflow: hidden;
    position: relative;
    line-height: normal;
    text-align: center;

    &.btn-simple {
      padding: 0;
    }

    &.btn-sm {
      height: $btn-icon-size-small;
      min-width: $btn-icon-size-small;
      width: $btn-icon-size-small;

      .fa,
      .far,
      .fas,
      .now-ui-icons {
        font-size: $btn-icon-font-size-small;
      }
    }

    &.btn-lg {
      height: $btn-icon-size-lg;
      min-width: $btn-icon-size-lg;
      width: $btn-icon-size-lg;

      .fa,
      .far,
      .fas,
      .now-ui-icons {
        font-size: $btn-icon-font-size-lg;
      }
    }

    &:not(.btn-footer) .now-ui-icons,
    &:not(.btn-footer) .fa,
    &:not(.btn-footer) .far,
    &:not(.btn-footer) .fas {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-12px, -12px);
      line-height: 1.5626rem;
      width: 24px;
    }
  }

  &:not(.btn-icon) .now-ui-icons {
    position: relative;
    top: 1px;
  }
}

.btn{
  &.border-radius-small{
    border-radius: $border-radius-button-small;
  }
  &.border-radius-large{
    border-radius: $border-radius-button-large;
  }
  .btn__text{
    margin-right: 30px;
    &.two-line{
      width: 110px;
      display: block;
      line-height: 12px;
      font-size:12px;
      float: left;
    }
  }
}

.btn-outline {
  background-color: transparent;
  border:2px solid $primary-color;
  color: $primary-color;
  height:36px;
  padding: 5px 15px;
  .btn__text{
    line-height: 14px;
    font-size:12px;
  }
  &:hover, &:active, &:focus{
    background-color: transparent !important;
    box-shadow: none!important;
    color: $primary-color !important;
    svg{
      path, circle, rect{
        stroke: $primary-color!important;
      }
    }
  }
}

.btn-secondary{
  background-color: $dark;
  border:2px solid $dark;
  color: #fff;
  height:36px;
  padding: 5px 15px;
  .btn__text{
    line-height: 18px;
    font-size:12px;
  }
  &:hover, &:active, &:focus{
    background-color: $dark !important;
    box-shadow: none!important;
    border:2px solid $dark !important;
    color: #fff;
    svg{
      path, circle, rect:not(.rect){
        stroke: #fff;
      }
    }
  }
}

.button-circle{
  display: flex;
  align-items: center;
  .btn-circle{
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-color: #A7061E;
    box-shadow: 0px 0px 20px #6F537780;
  }
  .button-circle__label{
    font-size:14px;
    font-weight: 600;
    line-height: 21px;
    color: $dark;
    margin-left: 15px;
  }
}

.btn-circle{
  background-color: $dark;
  border:2px solid $dark;
  color: #fff;
  height: 40px;
  width: 40px;
  padding: 5px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  .btn__text{
    font-size:10px;
    margin:0px;
  }
  &:hover{
    background-color: $dark;
    box-shadow: 0 0 20px #1a0707;
  }
}

// Apply the mixin to the buttons
// .btn-default { @include btn-styles($default-color, $default-states-color); }
.btn-primary {
  @include btn-styles($primary-color, $dark);
}
.btn-success {
  @include btn-styles($success-color, $success-states-color);
}
.btn-info {
  @include btn-styles($info-color, $info-states-color);
}
.btn-warning {
  @include btn-styles($warning-color, $warning-states-color);
}
.btn-danger {
  @include btn-styles($danger-color, $danger-states-color);
}
.btn-neutral {
  @include btn-styles($white-color, $white-color);
}

.btn {
  &:disabled,
  &[disabled],
  &.disabled {
    @include opacity(0.5);
    pointer-events: none;
  }
}
.btn-simple {
  border: $border;
  border-color: $default-color;
  padding: $padding-btn-vertical - 1 $padding-round-horizontal - 1;
  background-color: $transparent-bg;
}

.btn-simple,
.btn-link {
  &.disabled,
  &:disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $transparent-bg;
    }
  }
}

.btn-link {
  border: $none;
  padding: $padding-base-vertical $padding-base-horizontal;
  background-color: $transparent-bg;
}

.btn-lg {
  @include btn-size(
    $padding-large-vertical,
    $padding-large-horizontal,
    $font-size-large,
    $border-radius-large
  );
}
.btn-sm {
  @include btn-size(
    $padding-small-vertical,
    $padding-small-horizontal,
    $font-size-base,
    $border-radius-small
  );
}

.btn-wd {
  min-width: 140px;
}
.btn-group.select {
  width: 100%;
}
.btn-group.select .btn {
  text-align: left;
}
.btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px;
}

.btn-round {
  border-width: $border-thin;
  border-radius: $btn-round-radius;
  padding-right: $padding-round-horizontal;
  padding-left: $padding-round-horizontal;

  &.btn-simple {
    padding: $padding-btn-vertical - 1 $padding-round-horizontal - 1;
  }
}

.no-caret {
  &.dropdown-toggle::after {
    display: none;
  }
}

.group-btn{
  .btn{
    margin-right:10px;
  }
}