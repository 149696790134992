.a{
    fill:none;
    stroke:$danger-color;
    stroke-width:2px;
}

.gender-container {
    display: flex;
   
}

.custom-radio-button__container input[type="radio"] {
    display: none;
}

.custom-radio-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    cursor: pointer;
    border: 2px solid $danger-color; 
    color: $danger-color;
    height: 50px;
    width: 50px;
    font-size: 12px;
    box-shadow: 0 0 16px -6px #00000080;
    padding: 5px;
    border-radius: 10px;
    margin-right: 10px;
}

.custom-radio-button__container input[type="radio"]:checked + .custom-radio-button {
    border: 2px solid $danger-color;
    background-color: $danger-color;
    color: #fff;
    box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, .5);
}

.custom-radio-button__container input[type="radio"]:checked + .custom-radio-button .a {
    stroke: #fff;
}