.widget-item{
    .a{
        fill:#fcfcfc;
        opacity:0;
    }
    .b, .c{
        fill:none;
        stroke:#fff;
        stroke-linecap:round;
        stroke-linejoin:round;
        stroke-width:2px;
    }
}

.widget-container{
    display:flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    height: 20%;
    
    .widget-item{
        background-color: $dark;
        width: 230px;
        padding: 10px 15px;
        border-radius: 30px;
        height: 90px;
        display: inline-block;
        margin: 10px 10px 0 0;
    
        svg{
            height: 30px;
        }
    
        .w-info{
            display: flex;
            justify-content: space-between;
            
            .widget-label{
                align-self: center;
                width: 80%;
                height: 35px;
                line-height: 0.8;
    
                span {
                    color: #fff;
                    font-size: 12px;
                }
            }
    
            .widget-stats span{
                color: $danger-color;
                font-size: 30px;
            }
        }
    }
    .btn-aggiungi-cliente{
        margin-left: auto;
        height: 90px;
    }
}

