@import "now-ui-dashboard/variables";

body,
.main-panel {
  background-color: $bck-body;
}

/*-------- btn edit and delete -----*/
.nav-link__wrap {
  display: flex;
  align-items: center;

  .nav-link__wrap-name {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    text-align: start;
  }

  .nav-link__wrap-btn {
    top: 23px;
    width: auto;

    .btn-circle {
      background-color: #000000;
      border: none;
    }
  }
}

.bckg-body {
  background-color: $bck-body;
}

.title-page {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: $dark;
}

.border-bottom {
  border-bottom: 1px solid $primary-color !important;
}

.badge-type-food {
  cursor: pointer;
  font-size: 14px !important;
  line-height: 14px;
  min-height: 24px;
  text-transform: capitalize;
  font-weight: 400 !important;

  &:hover,
  &.active {
    border-color: $primary-color;
    background-color: $primary-color;
    color: #fff;
  }
}

/*------------------------
	     font personalized
-------------------------*/
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/Kanit-Thin.ttf");
}

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/Kanit-ExtraLight.ttf");
}

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Kanit-Light.ttf");
}

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Kanit-Regular.ttf");
}

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Kanit-Medium.ttf");
}

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Kanit-SemiBold.ttf");
}

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Kanit-Bold.ttf");
}

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/Kanit-ExtraBold.ttf");
}

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/Kanit-Black.ttf");
}

/*------------------------
	              svg icon
-------------------------*/
.svg-icons {

  &.size-20 {
    width: 20px;
    height: 20px;
  }

  &.size-24 {
    width: 24px;
    height: 24px;
  }

  &.bg-white {
    background-color: #fff;
    border-radius: 100%;
  }

  rect.a,
  rect.e,
  rect.d {
    fill: #fcfcfc;
    opacity: 0;
  }

  &.close-btn>g {
    stroke-width: 1.5px;
  }

  path,
  circle {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }

  &.dark {

    path,
    line,
    circle,
    rect {
      stroke: $black-color;
    }
  }

  &.primary {

    path,
    line,
    circle,
    rect {
      stroke: $primary-color;
    }
  }

  &.white {

    path,
    line,
    circle,
    rect:not(.rect) {
      stroke: #fff;
    }
  }

}

.modify-btn {
  .fa-edit {
    font-size: 14px;
    color: black;
  }

  &:hover,
  &:focus,
  &:active {
    .fa-edit {
      color: #A7061E;
    }
  }
}

.input-blk {
  border-radius: 10px;
  border: 2px solid #3C3C3C;
  color: #3C3C3C;
  width: 40%;
  margin-right: 5px;
  font-size: 12px;
  padding: 3px;

  &:focus {
    outline: none;
  }
}

.username_navbar {
  z-index: 9999;
  color: #1A0707;
  font-size: 25px;
  font-weight: 600;
  position: fixed;
  top: 15px;
}

.ng2-tag-input.regular-theme {
  tag {
    background-color: $dark !important;
    border-radius: 16px;
    display: flex;
    max-height: 32px;
    text-transform: unset;
    margin: 5px 10px 5px 0;
    width: auto;
    justify-content: center;

    .tag__text {
      font-family: 'Kanit';
      font-weight: 600;
      font-size: $font-size-base;
      line-height: 30px;
    }

    delete-icon svg {
      top: 11px;
    }
  }

}

.ng2-tag-input.regular-theme form input {
  color: $dark !important;
  font-weight: 500;

}

.ng2-tag-input.regular-theme form input::placeholder {
  color: $primary-color !important;
  font-weight: 400;
  font-style: italic;
}

.dp-hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.custom-day.focused {
  background-color: #e6e6e6;
}

.custom-day.range,
.custom-day:hover {
  background-color: $primary-color;
  color: #fff;
}

.custom-day.faded {
  background-color: $primary-op30;
  color: #fff;
}

.input-search-datatable__wrap {
  .input-search-datatable {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid $primary-color;
    position: relative;
    padding: 3px 30px;
    font-size: 12px;
    font-weight: 600;
    color: #3C3C3C;
    background-color: transparent;
  }

  .svg-icons {
    position: absolute;
    top: 0;
    left: 15px;
  }
}

.square-input {
  border-radius: unset;
  border: 0px;

  &:focus {
    background-color: #fcf7f7;
  }
}

.badge-outline {
  border: 2px solid $dark;
  background-color: transparent;
  color: $dark;
  font-size: 10px;
  font-weight: 500;
}

.btn.btn-shadow {
  box-shadow: 0px 0px 20px #1A070781;
  padding: 5px !important;
  font-size: 12px;
}

.plus {
  border: 1px solid #fff;
  border-radius: 100%;
  padding: 3px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.wrap-multiselect .cuppa-dropdown {
  margin-bottom: 10px;
}

.modal {
  z-index: 9999;
  background: rgba(26, 7, 7, 0.96);
}

.primaryModal .modal-content {
  margin-top: 60% !important;
  background-color: $primary-color;
  padding: 15px;

  h5 {
    color: $dark;
  }

  .modal-body {
    color: #fff;
  }
}

.dark-input {
  background-color: $dark;
  color: #fff;
  padding: 7px;
  border-radius: 15px;
  border: 0;
  width: 33%;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    color: #fff;
  }
}

.white-input {
  background-color: #fff;
  color: $primary-color;
  padding: 7px;
  border-radius: 15px;
  border: 0;
  width: 33%;

  &:focus {
    outline: 0;
  }
}

.dark-btn {
  background-color: $dark;
  color: #fff;

  &:hover {
    background-color: $dark;
    color: #fff;
    box-shadow: 0 0 20px $dark !important;
  }
}

.info-food-of-meal {
  padding: 0;
  margin: 0;
  color: #fff;

  .info-food-of-meal__item {
    list-style: none;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;

    .info-food-of-meal__name {
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .info-food-of-meal__data {
      font-weight: 400;
      border-bottom: 1px solid;
    }

    .info-food-of-meal__note {
      font-size: 12px;
    }
  }
}

/*------------------------
	    custom datatable
-------------------------*/
ng2-smart-table {
  table {
    thead {
      color: #3C3C3C;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;

      td,
      th {
        padding: 0.8em 0.4em !important;
      }
    }

    tbody {
      font-weight: 400;

      .black-bold {
        font-weight: 600;

        img {
          height: 15px;
          padding-right: 10px;
        }
      }

      tr {
        border-bottom: 1px solid #3C3C3C !important;
        font-size: 15px;

        &:hover {
          cursor: pointer;
          background-color: #FCF7F7;
        }
      }

      tr:nth-child(1) {
        border-top: 1px solid lightgrey;
      }

      td,
      th {
        padding: 0.8em 0.4em !important;

        button.dropdown-toggle {
          padding: 0.5rem 0.6em;
        }
      }
    }

    tr {
      th:last-child {
        ng2-st-column-title {
          float: right;
        }
      }

      td:last-child {
        ng2-smart-table-cell {
          float: right;
          position: relative;
          bottom: -5px;
          text-align: right;
        }

        .action-btn {
          align-items: center;
          justify-content: space-between;
          display: inline-block;
        }
      }
    }
  }

  .form-control {
    border: 0;
    border-radius: 0;
    padding: 0 !important;
    font-size: 12px;

    &::placeholder {
      color: lightgrey;
      font-size: 12px;
    }
  }
}

ng2-smart-table-title>a {
  color: #3C3C3C !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
}

.main-section {
  padding: 100px 30px 30px;
  background-color: #FCF7F7;
  min-height: 100vh;
}

.container-table-index {
  padding: 15px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 0px 20px #0000001a;
  max-height: 80%;

  .nonassegnato .fa-circle {
    color: $not-assigned;
  }

  .inscadenza .fa-circle {
    color: $expiring;
  }

  .scaduto .fa-circle {
    color: $expired;
  }

  .incorso .fa-circle {
    color: $active;
  }

  .draft .fa-circle {
    color: $draft;
  }

  .dacominciare .fa-circle {
    color: $to-start;
  }
}

.container-table-custom {

  .dacominciare .fa-circle {
    color: $to-start;
  }

  .completato .fa-circle {
    color: #72E049;
  }

  .incorso .fa-circle {
    color: #FFA245;
  }

  .form-control {
    border: 0;
    border-radius: 0;
    padding: 0 !important;
    font-size: 12px;
    background-color: #FCF7F7;
  }

  .form-control::placeholder {
    color: lightgrey;
    font-size: 12px;
  }

}

.container-table-modal {

  .dacominciare .fa-circle {
    color: $primary-color;
  }

  .completato .fa-circle {
    color: #72E049;
  }

  .incorso .fa-circle {
    color: #FFA245;
  }

  .form-control {
    border: 0;
    border-radius: 0;
    padding: 0 !important;
    font-size: 12px;
    background-color: #fff;
  }

  .form-control::placeholder {
    color: lightgrey;
    font-size: 12px;
  }

}

/*------------------------
	    custom tab title
-------------------------*/
.tab-container {
  padding: 70px 0 0;
  background-color: #FCF7F7;
  min-height: 100vh;
  margin-bottom: 90px;

  ul li {
    flex-grow: 1;
    background-color: #FCF7F7;
  }
}

.measure .form-group {
  display: flex;

  .col-form-label {
    position: absolute;
    right: 5%;
    font-size: 14px;
    line-height: 12px;
    text-align: right;
    color: $primary-color !important;
    font-weight: 300;
  }
}

.form-group.dark-border {
  input {
    border: 2px solid $dark;
    border-radius: 10px;
    color: $dark;
  }
}

.wrap-accordion--details {
  z-index: 0;

  .accordion-item {
    .accordion-header {
      .accordion-button {
        a {
          .accordion-button__wrap-btn {
            opacity: 0;

            .btn-circle {
              pointer-events: none;
            }
          }

          .nutritional_values {
            .text {
              font-weight: 200;
              font-size: 16px;
              position: relative;

              b {
                font-weight: 400;
                font-size: 14px;
              }
            }
          }
        }
      }

      .accordion-button[aria-expanded="true"] {
        .accordion-button__wrap-btn {
          opacity: 1;

          .btn-circle {
            pointer-events: unset;
          }
        }
      }
    }
  }
}

.measure-box {
  background-color: #fff;
  box-shadow: 0px 0px 20px #6F537734;
  border-radius: 30px;

  .measure-box__number {
    font-size: 35px;
    line-height: 35px;
  }

  .measure-box__measure {
    font-size: 18px;
  }

  .measure-box__label {
    color: $primary-color;
    font-size: 12px;
    line-height: 12px;
  }

  .measure-box__svg {
    background-color: $primary-color;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
  }
}

.progress-photos {
  width: 100%;

  .progress-photos__item {
    min-width: 45%;
    width: 45%;
    height: 150px;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
    position: relative;

    img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.overlay-photo {
  overflow: hidden;
  background-color: #ffffff;
  position: relative;

  img.img-preview-overlay {
    transition-duration: 1s;
    -ms-transition-duration: 1s;
    -webkit-transition-duration: 1s;
    -moz-transition-duration: 1s;
    -o-transition-duration: 1s;
    transition-property: transform;
    position: relative;
  }

  &:hover {
    img.img-preview-overlay {
      filter: brightness(50%);
      transform: scale(1.15, 1.15);
    }
  }

  .overlay-photo-btn {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;

    &:hover {
      opacity: 1;
    }

    .overlay-photo-btn-area {
      cursor: pointer;
      background-color: #fff;
      border-radius: 2em;
      margin-bottom: 5px;
      height: 40px;
      width: 130px;
      display: flex;
      padding: 10px 15px 10px 3px;
      align-items: center;
      border: 2px solid #A7061E;
      color: #000;
      justify-content: space-between;

      svg {
        border-radius: 100%;
        width: 30px;
        height: 30px;
        text-align: center;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        background-color: $primary-color;
      }

      i {
        border-radius: 100%;
        width: 30px;
        height: 30px;
        text-align: center;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        background-color: $primary-color;
      }

      span {
        font-size: 15px;
      }
    }
  }
}

.datepickerSubscription {
  .ngb-dp-months {
    display: flex;
    flex-wrap: wrap;
    width: 800px;

    .ngb-dp-month {
      pointer-events: none;
      flex-grow: 1;
      width: 33%;
    }
  }
}

.food-plan-group-info {

  /*justify-content: end;
  background-color: #1e1e1ef7;
  border-radius: 20px;
  color: #fff;
  padding: 0px 20px;
  box-shadow: 0 0 20px #6f537734;*/
  .text {
    font-weight: 200;
    font-size: 18px;
    line-height: 22px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      border-radius: 50%;
      height: 4px;
      width: 4px;
      background: $primary-color;
      left: -10px;
      top: 50%;
    }

    b {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .text:first-child {
    &:after {
      content: unset;
    }
  }
}

.food-plan-info {
  background-color: $black-bg;
  border-radius: 20px;
  color: #fff;
  padding: 20px;
  box-shadow: 0 0 20px #6f537734;

  .food-plan-info__data {
    width: 100%;
    margin-left: auto;

    .text {
      width: 100%;
      text-align: right;
      font-weight: 200;
      font-size: 22px;
      line-height: 24px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      b {
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
}

.btn-back {
  background-color: transparent;
  border: none;
  font-size: 12px;
  line-height: 0;
}

.wrap-spinner {
  height: 100vh;
  background: #fff;
}

.header-video {
  display: flex;
  align-items: center;
  gap: 40px;

  button {
    width: 200px;

    p {
      margin-bottom: 0;
      text-align: center;
    }
  }
}

.card-video {
  width: auto;
  box-shadow: 5px 5px 8px 0px lightgray;

  .card-header {
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 200px;
      white-space: nowrap;
    }
  }

  video {
    height: 250px;
    width: 300px;
  }
}

.wrap-video-exercise-associates {
  height: 250px;

  video {
    max-height: 100%;
  }
}

.banner-integrazione {
  .thumbnail.img-raised {
    max-width: unset;
  }
}

.sidebar .sidebar-wrapper,
.off-canvas-sidebar .sidebar-wrapper {
  overflow: scroll;
}

.notificationsSidepanel-activated {
  overflow: hidden !important;
  pointer-events: none;
}

.btn-notificationsSidebar {
  position: relative;

  .newNotifications {
    width: 7px;
    height: 7px;
    background-color: red;
    border-radius: 100%;
    position: absolute;
    top: 10px;
    right: 13px;
  }
}

#notificationsSidepanel {
  &.active {
    width: 350px;
    box-shadow: 0 0 0 100vmax rgba(0, 0, 0, .5);
    pointer-events: auto !important;
  }
}

.black-accordion .accordion-item {
  background-color: #ffffff !important;
  box-shadow: 0px 2px 10px #00000029;
  padding: 0;

  .accordion-header {
    background-color: #000000;
    border-radius: 11px;
    padding: 0;
    overflow: hidden;
  }

  button.accordion-button {
    padding: 0.75rem;
  }

  button.accordion-button-loader {
    font-size: 13px;
    padding: 0.8em 2em;
    text-transform: uppercase;
    border-radius: 12px;
    height: 55px;
    min-width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-container {
    width: 100%;
    border: 2px solid #E1E1E1;
    padding: 15px;
    border-radius: 5px;
  }
}

.skeleton-image {
  height: 250px;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-animation: skeleton-loading 1s linear infinite alternate;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
  height: 20px;
  width: 100%;
  -webkit-animation: skeleton-loading 1s linear infinite alternate;
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-session-wrapper {
  width: 66%;

  .skeleton-session {
    width: 100%;
    height: 100px;
    border-radius: 15px !important;
    -webkit-animation: skeleton-loading 1s linear infinite alternate;
    animation: skeleton-loading 1s linear infinite alternate;
  }
}

.skeleton-food-list-wrapper {
  .skeleton-session {
    width: 100%;
    height: 30px;
    border-radius: 15px !important;
    -webkit-animation: skeleton-loading 1s linear infinite alternate;
    animation: skeleton-loading 1s linear infinite alternate;
  }
}

@-webkit-keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }

  100% {
    background-color: #f0f3f5;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: #c2cfd6;
  }

  100% {
    background-color: #f0f3f5;
  }
}

.int-progress {
  font-size: 12px;
  font-weight: 600;
}

.countdown {
  font-size: 10px;
  color: #6F6F6F;
  display: flex;
  align-items: center;

  img {
    width: 14px;
    margin-right: 5px;
  }
}

.icon-table {
  img {
    width: 14px;
    margin-right: 5px;
  }

  &.with-tooltip {
    position: relative;

    &:after {
      opacity: 0;
      transition: opacity 1s;
    }

    &:hover {
      &:after {
        background: #fff;
        border-radius: 4px;
        color: #707070;
        border: 1px solid #707070;
        content: attr(title);
        font-size: 9px;
        padding: 0px 3px;
        opacity: 1;
        position: absolute;
        top: 10px;
        white-space: nowrap;
        line-height: 12px;
      }
    }
  }
}

ng2-smart-table-pager {
  padding-top: 20px;
  justify-content: right !important;
}

// reference tag for template name and creation date

.reference-tag {
  background-color: #EFD2D2;
  border-radius: 6px;
  padding: 0.3em 0.8em;
  display: flex;
  align-items: center;

  i {
    margin-right: 0.5em;
  }

  b {
    margin: 0.3em;
  }
}

.form-group label {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: $dark;
  text-transform: uppercase;
}

.sending-massive-notifications {
  .form-group {

    .form-control,
    .custom-textarea.with-shadow {
      padding: 10px 25px 10px 25px;
    }
  }

  .card {
    .card-body {
      padding: 25px;
    }
  }

  sup {
    color: $primary-color;
  }

  .checkAllBox {
    font-size: 16px;
    font-weight: 600;
  }

  table {
    thead {
      th {
        font-size: 12px;
        color: #8B8B8B;
        text-transform: uppercase;
        font-weight: 500;
      }
    }

    tr {
      td {
        img {
          width: 16px;
          margin-right: 10px;
        }

        i {
          font-size: 16px;
          margin-right: 10px;
        }
      }

      td:first-child {
        border-right: 1px solid #dee2e6;
        text-align: center;
      }
    }
  }

  .sending-massive-notifications-filter-row {
    .filter-search {
      border-bottom: 1px solid #3c3c3c3d;
      width: auto;

      i {
        color: $primary-color;
        font-size: 23px;
        margin-right: 15px;
      }

      input {
        border: 0;
        width: 80%;
        color: #000;

        &::placeholder {
          font-size: 14px;
          font-weight: 600;
          color: #000;
        }

        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}

.chat-float-button {
  height: 65px;
  width: 65px;
  position: fixed;
  bottom: 3%;
  right: 1.5%;
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  i {
    font-size: large;
    margin-bottom: 1px;
  }

  span {
    font-weight: 200;
    font-size: 14px;
  }
}

.details-sub {
  span {
    font-size: 0.875em;
  }
}

.action-btn {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 12px;
  background-color: #A7061E;
  padding: 5px 10px;
  margin-left: 8px;
  margin-top: 4px;
  border-radius: 10px;
  color: #ffffff;
  transition-duration: 0.15s;
  transition-property: background-color, border-color, box-shadow, color;
  transition-timing-function: ease-in;
}

//uniform font-size and color
.general-modal {
  .custom-color {
    color: #000;

    span {
      color: #A7061E;
      text-transform: uppercase;
    }

    input {
      color: #000;
      padding-left: 0px;
    }
  }
}

//back button style
button.back-button {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 15px;
  background-color: white;
  border: 1px solid lightgrey;
  cursor: pointer;
  font-size: 18px;
  box-shadow: 0 0 5px 2px lightgray;
  border-radius: 5px;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid white;

  &:active {
    box-shadow: none;
    border: 1px solid lightgray;
    outline: none;
  }

  i {
    margin-right: 4px;
  }

  &.disabled {
    background-color: #ebebeb;
    box-shadow: none;
    border: none;
    cursor: default;

    &:focus {
      box-shadow: none;
      border: none;
      outline: none;
    }
  }
}

.flex-column{flex-direction: column;}
.wrap-table-progress{
  width: 100%;
  overflow: scroll;
}
.row-table-progress{
  .col {
    padding: 10px;
    margin-bottom: 15px;
  }
  .col:first-child {
    width: 120px;
    flex: unset;
    color: $primary-color;
    font-size: 16px;
    font-weight: 500;
    padding: 20px 0;
    white-space: nowrap;
  }
  .col:nth-child(2) {
    width: 120px;
    flex: unset;
    color: $primary-color;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0;
    text-align: center;
  }
  .row-label{
    .col-label{
      background-color: $primary-color;
      color: #fff;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      text-align: center;
      vertical-align: middle;
      width: 80px;
      margin-bottom: 15px;
      padding: 15px;
      display: flex;
      align-items: center;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: inherit;
        writing-mode: vertical-lr;
        text-orientation: mixed;
        font-weight: 500;
      }
    }
    .col-label:first-child {
      max-height: 80px;
      span {
        writing-mode: horizontal-tb;
      }
    }
    .col-label:nth-child(2) {
      max-height: 80px;
      span {
        writing-mode: horizontal-tb;
      }
    }
  }
  .img-wrap{
    padding: 5px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
  }
  .container-image {
    height: 300px;
    overflow: hidden;
    display: flex;
    align-items: center;
    object-fit: cover;
  }
}
.veryLargeModal {
  .modal-dialog {
    max-width: 800px;
  }
}

.pickDateModal {
  .modal-content {
    background-color: $primary-color;
    .modal-header {
      .close {
        color: #fff;
        text-shadow: none;
      }
    }
  }
  
  .modal-dialog {
    max-width: 800px;
  }
  
  ngb-datepicker {
    position: unset !important;
    background-color: #fff;
    border: none;
  }
  
  .selected {
    background-color: $primary-color;
    border-radius: 1rem;
    color: white;
  }

  .btn-primary {
    border: 1px solid #fff;
    padding: 2px 36px !important;
  }
}